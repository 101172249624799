<template>
  <v-container
    id="user-profile"
    fluid
    tag="section"
  >
    <v-row justify="center">
      <v-col
        cols="12"
        md="8"
      >
        <base-material-card>
          <template #heading>
            <div class="text-h3 font-weight-light">
              Hello {{ user.firstName }}
            </div>

            <div class="text-subtitle-1 font-weight-light">
              Information about your profile
            </div>
          </template>

          <v-form>
            <v-container class="py-0">
              <v-row>
                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Company"
                    disabled
                    :value="user.homeOrg"
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    class="purple-input"
                    label="User ID"
                    :value="user.userId"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="4"
                >
                  <v-text-field
                    label="Email Address"
                    class="purple-input"
                    :value="user.email"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="First Name"
                    class="purple-input"
                    :value="user.firstName"
                    disabled
                  />
                </v-col>

                <v-col
                  cols="12"
                  md="6"
                >
                  <v-text-field
                    label="Last Name"
                    class="purple-input"
                    :value="user.lastName"
                    disabled
                  />
                </v-col>

                <v-col cols="12">
                  <pre>
                  {{ user }}
                </pre>
                </v-col>
              </v-row>
            </v-container>
          </v-form>
        </base-material-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
      >
        <base-material-card
          class="v-card-profile"
          avatar="https://demos.creative-tim.com/vue-material-dashboard/img/marc.aba54d65.jpg"
        >
          <v-card-text class="text-center">
            <h6 class="text-h4 mb-1 grey--text">
              {{ user.homeOrg }}
            </h6>

            <h4 class="text-h3 font-weight-light mb-3 black--text">
              {{ user.firstName }}  {{ user.lastName }}
            </h4>

            <p class="font-weight-light grey--text">
              {{ user.groups }}
            </p>
          </v-card-text>
        </base-material-card>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
  import { mapState } from 'vuex'
  export default {
    computed: {
      ...mapState({
        user: state => state.user || null,
      }),
    },
  }
</script>
